<template>
  <div class="blue-net">
    <div class="wrap-content">
      <!-- 顶部盒子 -->
      <div class="top_box">
        <div class="top-title">
          <div class="left">
            <img :src="navImg" alt="" />
          </div>
          <div class="theme-title">
            <img :src="iconKey" alt="" />
            <span>会诊主题描述</span>
          </div>
          <div class="right">
            <img :src="navImg2" alt="" />
            <img :src="navImg3" alt="" />
          </div>
        </div>
        <div class="time">主持人：张三 | 时间：00:01:24</div>
        <div class="viewport">
          <div class="viewport_item">
            <img :src="viewportImg1" alt="" />
            <div class="info">
              <div class="top">
                <span>吕一</span>
                <img :src="iconVoiceNo" />
              </div>
              <div class="text">深圳市*****人民…</div>
            </div>
            <div class="bg"></div>
          </div>
          <div class="viewport_item">
            <img :src="viewportImg2" alt="" />
            <div class="info">
              <div class="top">
                <span>孙二</span>
                <img :src="iconVoiceOff" />
              </div>
              <div class="text">深圳市*****人民…</div>
            </div>
            <div class="bg"></div>
          </div>
          <div class="viewport_item">
            <img :src="viewportImg3" alt="" />
            <div class="info">
              <div class="top">
                <span>孙二</span>
                <img :src="iconVoiceOff" />
              </div>
              <div class="text">深圳市*****人民…</div>
            </div>
            <div class="bg"></div>
          </div>
        </div>
      </div>
      <!-- 内容 -->
      <div class="content-box">
        <div class="images-box">
          <div class="top">
            <div class="speaking_show">正在发言：张三</div>
            <div class="speaking_show2">
              <div class="drop"></div>
              <span>录制中</span>
            </div>
            <div class="speaking_show2">
              <div class="drop"></div>
              <span>停止共享</span>
            </div>
          </div>
          <div class="tips"><span>李四刚刚退出了会议</span></div>
        </div>
      </div>

      <div class="footer">
        <div class="show_chat_box">
          <div class="chat-item" v-for="(item, index) in chatList" :key="index">
            <span style="color: #f3b13e" v-if="item.isShow">(私聊)</span>
            <span style="color: #3e93f3; margin: 0 5px">{{ item.name }}:</span>
            <span>{{ item.text }}</span>
          </div>
          <div class="send">
            <span>说点什么</span>
            <img :src="roateIcon" alt="" />
          </div>
        </div>

        <div class="tabbar_box">
          <div
            class="tab_item"
            v-for="(item, index) in tabbarIconList"
            :key="index"
          >
            <img :src="item.imgIcon" alt="" />
            <span>{{ item.tabName }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
// 顶部盒子图片
const navImg = require("@/assets/images/imitatepage/blue-net/icon_top1.png");
const navImg2 = require("@/assets/images/imitatepage/blue-net/icon_top2.png");
const navImg3 = require("@/assets/images/imitatepage/blue-net/icon_top3.png");
const iconKey = require("@/assets/images/imitatepage/blue-net/icon_key.png");

// 视口图片
const viewportImg1 = require("@/assets/images/imitatepage/blue-net/avata1.png");
const viewportImg2 = require("@/assets/images/imitatepage/blue-net/default_avata.png");
const viewportImg3 = require("@/assets/images/imitatepage/blue-net/avata2.png");

//语音图标
const iconVoiceNo = require("@/assets/images/imitatepage/blue-net/icon_voice_no.png");
const iconVoiceOff = require("@/assets/images/imitatepage/blue-net/icon_voice2.png");

//旋转图标
const roateIcon = require("@/assets/images/imitatepage/blue-net/icon_roate.png");

//图片列表
const chatList = [
  {
    isShow: true,
    name: "李四",
    text: "我进来了",
  },
  {
    isShow: false,
    name: "张三丰",
    text: "画面不是很清晰啊，重新退了一下",
  },
  {
    isShow: false,
    name: "赵武",
    text: "没进来的，大家等等，不要着急，先做个笔记。",
  },
];

// 底部操作图标
const tabbarIconList = [
  {
    imgIcon: require("@/assets/images/imitatepage/blue-net/icon_operate1.png"),
    tabName: "取消静音",
  },
  {
    imgIcon: require("@/assets/images/imitatepage/blue-net/icon_operate2.png"),
    tabName: "摄像头",
  },
  {
    imgIcon: require("@/assets/images/imitatepage/blue-net/icon_operate3.png"),
    tabName: "",
  },
  {
    imgIcon: require("@/assets/images/imitatepage/blue-net/icon_operate4.png"),
    tabName: "扬声器",
  },
  {
    imgIcon: require("@/assets/images/imitatepage/blue-net/icon_operate5.png"),
    tabName: "参会者(2)",
  },
];
</script>

<style lang="scss" scoped>
.blue-net {
  position: relative;
  width: 100%;
  height: 100%;

  .wrap-content {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 40px;
    box-sizing: border-box;
    overflow: hidden;
    display: grid;
    grid-template-rows: auto 1fr;
    padding: 0 2px;
    background-color: #1d2024 !important;

    // 顶部视口盒子
    .top_box {
      height: 223px;
      color: white;
      .top-title {
        display: grid;
        grid-template-columns: auto 1fr auto;
        align-items: center;
        justify-items: center;
        margin-top: 55px;
        padding: 0 12px;
        .left {
          display: flex;
          align-items: center;
          img {
            width: 24px;
            height: 24px;
          }
        }
        .theme-title {
          display: flex;
          align-items: center;
          font-weight: bold;
          margin-left: 30px;

          img {
            width: 9px;
            height: 11px;
            margin-right: 5px;
          }
        }
        .right {
          display: flex;
          align-items: center;

          img {
            width: 24px;
            height: 24px;

            &:last-child {
              margin-left: 15px;
            }
          }
        }
      }

      .time {
        font-size: 12px;
        margin-top: 3px;
        text-align: center;
      }

      .viewport {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-right: 15px;
        margin-top: 20px;
        .viewport_item {
          position: relative;
          width: 88px;
          height: 96px;
          display: flex;
          justify-content: center;
          margin-left: 10px;

          img {
            width: 100%;
            height: 100%;
          }

          .info {
            position: absolute;
            bottom: 0;
            left: 0;
            color: white;
            padding: 4px 3px 4px 4px;
            box-sizing: border-box;
            z-index: 2;
            .top {
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-top: 3px;
              span {
                font-size: 10px;
              }

              img {
                width: 10px;
                height: 10px;
              }
            }
            .text {
              font-size: 10px;
            }
          }

          .bg {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: linear-gradient(180deg, transparent 0%, #05081147 100%);
          }

          &:nth-child(2) {
            background-color: #000000;
            > img {
              width: 52px;
              height: 52px;
              margin-top: 10px;
            }
          }

          &:nth-child(3) {
            background-color: #000000;
            > img {
              width: 87px;
              height: 46px;
              margin-top: 16px;
            }
          }
        }
      }
    }

    // 内容
    .content-box {
      box-sizing: border-box;
      height: 256px;

      .images-box {
        height: 100%;
        background-image: url(../../../../assets/images/imitatepage/blue-net/icon_content.png);
        background-repeat: no-repeat;
        background-attachment: inherit;
        background-position: center center;
        background-size: cover;
        img {
          width: 100%;
          height: 100%;
        }

        .top {
          display: flex;
          align-items: center;
          color: white;
          font-size: 12px;
          width: 100%;
          padding: 5px 15px;
          box-sizing: border-box;
          justify-content: space-between;
          .speaking_show {
            width: 105px;
            height: 30px;
            background: rgba(0, 0, 0, 0.3);
            border-radius: 15px;

            text-align: center;
            line-height: 30px;
          }

          .speaking_show2 {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 30px;
            border-radius: 15px;
            background-color: rgba(#f52338, 0.33);
            padding: 5px 10px;
            box-sizing: border-box;
            color: #f52338;
            .drop {
              width: 5px;
              height: 5px;
              background: #f52338;
              margin-right: 5px;
            }
          }
        }

        .tips {
          width: 100%;
          text-align: center;
          margin-top: 80px;
          span {
            display: inline-block;
            width: 214px;
            height: 30px;
            color: white;
            font-size: 12px;
            background: rgba(0, 0, 0, 0.68);
            line-height: 30px;
            border-radius: 4px 4px 4px 4px;
          }
        }
      }
    }

    // tabbar
    .footer {
      position: sticky;
      bottom: 0;
      width: 100%;
      box-sizing: border-box;
      z-index: 2;

      .show_chat_box {
        display: flex;
        flex-direction: column;
        padding: 8px 15px 20px;
        .chat-item {
          height: 26px;
          width: fit-content;
          background: rgba(255, 255, 255, 0.08);
          border-radius: 22px 22px 22px 22px;
          color: white;
          font-size: 12px;
          margin-bottom: 5px;
          padding: 5px 10px;
          box-sizing: border-box;
        }

        .send {
          display: flex;
          align-items: center;
          justify-content: space-between;
          color: #b1bbc9;
          font-size: 12px;
          margin-top: 18px;

          span {
            display: inline-block;
            width: 163px;
            height: 28px;
            padding: 5px 10px;
            box-sizing: border-box;
            background: rgba(255, 255, 255, 0.08);
            border-radius: 22px 22px 22px 22px;
          }
          img {
            width: 28px;
            height: 28px;
          }
        }
      }

      // 底部操作区域
      .tabbar_box {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        padding: 0 0 12px 0;
        .tab_item {
          display: flex;
          flex-direction: column;
          align-items: center;
          font-size: 11px;
          color: rgba(255, 255, 255, 0.8);
          img {
            width: 48px;
            height: 48px;
            margin-bottom: 5px;
          }
        }
      }
    }
  }
}
</style>
